import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getItem } from "../../utils/storage";
import {
  getReqParamheader,
  postReqParamheaderFile,
} from "../../services/apiCall";
const HtmlToReactParser = require("html-to-react").Parser;

function DefaultArtical({
  data,
  id,
  hs,
  hf,
  ts,
  tf,
  type,
  save,
  read,
  currentid,
}) {
  const [articleData, setArticleData] = useState(data || {});
  const htmlToReactParser = new HtmlToReactParser();
  const containerRef = useRef(null);
  const path = useLocation().pathname;

  useEffect(() => {
    if (id) {
      getArticleData(id);
    }
  }, [id]);

  const getArticleData = async (id) => {
    const apiurl = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
    try {
      const res = await getReqParamheader(apiurl);
      if (res?.status) {
        setArticleData(res?.data?.data?.draft);
      } else {
        console.error("Response error", res?.message);
      }
    } catch (err) {
      console.error("Error fetching article data", err);
    }
  };

  console.log("articleData : ", articleData.Content);

  useEffect(() => {
    if (containerRef.current) {
      let img = new Image();
      img.src = articleData?.Featured_Photo;
      img.onload = () => {
        let aratio = (img.height / img.width).toFixed(2);
        let w = img.width > 600 ? 600 : img.width;
        let h = img.width > 600 ? 600 * aratio : img.height;
        save(currentid, w.toString(), h.toString());
      };
    }
  }, [articleData, save, currentid]);

  useEffect(() => {
    const userid = getItem("user_id");
    const orgid = path.split("/")[2];
    setArticleData((prevData) => ({ ...prevData, org: orgid, user: userid }));
  }, [path]);

  const newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const desL = htmlToReactParser.parse(articleData?.Content?.slice(0, 300));
  const desM = htmlToReactParser.parse(articleData?.Content);
  const title = htmlToReactParser.parse(articleData?.Title);
  const ed = htmlToReactParser.parse(articleData?.Event_Description);
  const ed_excerpt = htmlToReactParser.parse(
    articleData?.Event_Description?.slice(0, 300)
  );
  const url = `https://smorgborg.org/view/${id}`;

  return (
    <>
      {type && type === "event" ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}>
            <a
              target="_blank"
              href={data?.Event_Website}
              rel="noopener noreferrer"
            >
              {data?.Event_Name}
            </a>
          </p>
          {data?.Featured_Photo && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <a
                target="_blank"
                href={data?.Event_Website}
                rel="noopener noreferrer"
              >
                <img
                  ref={containerRef}
                  src={data?.Featured_Photo}
                  style={{ maxWidth: "600px", width: "100%", height: "auto" }}
                  alt="feature photo"
                />
              </a>
            </div>
          )}
          <p style={{ fontSize: `${ts}`, fontFamily: `${tf}` }}>
            {data?.Event_Start_Date.toLocaleString("en-US", newDateOptions)}
          </p>
          <p
            style={{
              fontSize: `${ts}`,
              fontFamily: `${tf}`,
              maxWidth: "600px",
              margin: "0 auto",
            }}
          >
            {read ? ed_excerpt : ed}
            {read && (
              <a
                href={data?.Event_Website}
                target="_blank"
                rel="noopener noreferrer"
              >
                ...Read more
              </a>
            )}
          </p>
        </div>
      ) : (
        <div className="" style={{ width: "100%", textAlign: "justify" }}>
          <a target="_blank" href={url} rel="noopener noreferrer">
            <p
              style={{
                fontSize: `${hs}`,
                fontFamily: `${hf}`,
                marginBottom: "6px",
              }}
            >
              {title}
            </p>
          </a>
          {articleData?.Featured_Photo && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "6px",
              }}
            >
              <a target="_blank" href={url} rel="noopener noreferrer">
                <img
                  ref={containerRef}
                  src={articleData?.Featured_Photo}
                  style={{
                    maxWidth: "600px",
                    width: "100%",
                    height: "auto",
                    maxHeight: "600px",
                    objectFit: "contain",
                  }}
                  alt="feature photo"
                />
              </a>
            </div>
          )}
          <p
            style={{
              fontSize: `${ts}`,
              fontFamily: `${tf}`,
              maxWidth: "600px",
              maxHeight: "600px",
              padding: "0 auto",
              margin: 0,
            }}
          >
            {read ? desL : desM}
            {read && (
              <a href={url} target="_blank" rel="noopener noreferrer">
                ...Read more
              </a>
            )}
          </p>
        </div>
      )}
    </>
  );
}

export default DefaultArtical;
