import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import Modal from "../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import CustomButton from "../components/CustomButton";
import { UserPlus, Frown } from "lucide-react";

import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../services/apiCall";
import { getItem, setItem, deleteItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";

const user = {};

function TeamHome() {
  const [emailList, setEmailList] = useState([]);
  const [memberData, setMemberData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Role: "",
  });
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUser(userid);
    deleteItem("member");
    getUserById(userid);
  }, []);

  useEffect(() => {
    if (orgId && userId && userId) {
      getMemberData();
    }
  }, [orgId, currentUser]);
  const getUserById = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/user/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('response get user by id ', res?.data?.data)
          setCurrentUser(res?.data?.data);
        } else {
          console.log("response err", res?.message);
          // toast.error(`${res?.message}`)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const getMemberData = () => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/Team_details/${orgId}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get member list response", res?.data?.data);
          if (currentUser) {
            // setEmailList([{
            //     Email: currentUser?.email,
            //     FirstName: currentUser?.firstName,
            //     LastName: currentUser?.lastName,
            //     Role: 'Owner',
            //     status: 'Active'
            // }
            //     , ...res?.data?.data])
            console.log("getMemberData : ", res?.data?.data);
            setEmailList(res?.data?.data);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const inviteMember = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Invite_user/${orgId}/${userId}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log('invite member response', res?.data)
          getMemberData();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    // console.log('handle edit::::', id, path)
    setItem("member", "edit");
    navigate(`${path}/${id}`);
  };
  const handleRemove = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Delete_invite/${id}/${orgId}/${userId}`;
    console.log(url);
    deleteReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('delete member response', res?.data)
          getMemberData();
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSubmit = () => {
    let data = memberData;
    console.log("data : ", data);
    if (data?.Email && data?.Role) {
      setShowModal(false);
      inviteMember(data);
    } else {
      alert("Please fill all details");
    }
  };

  let teamListTemplate = null;
  if (loading) {
    teamListTemplate = <PageSnipper loading={loading} />;
  } else if (!loading && emailList?.length > 0) {
    teamListTemplate = (
      <BaseTable
        header={[
          "Email",
          "First Name",
          "Last Name",
          "Role",
          "Status",
          "Actions",
        ]}
        data={emailList}
        edit={handleEdit}
        remove={handleRemove}
        type="team"
      />
    );
  } else {
    teamListTemplate = (
      <div className="w-full h-[60vh] flex justify-center flex-col items-center gap-8">
        <Frown size={48} color="#6a6868" strokeWidth={1.25} />
        <p className="text-lg text-gray-600 font-medium">
          No Team Members found
        </p>
      </div>
    );
  }

  const handleInvite = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="px-6">
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm md:text-lg lg:text-xl font-medium">
          {" "}
          Team Members ({emailList.length})
        </p>
        <CustomButton
          icon={<UserPlus size={20} />}
          bgColor={"bg-blue-600"}
          content={"Invite a Member"}
          clickHandler={handleInvite}
          additionalStyles={"text-white hover:bg-blue-700"}
        />
      </div>
      <div>
        {teamListTemplate}
        {showModal && (
          <Modal setOpenModal={setShowModal} title="Invite a Team Member">
            <label className="text-gray-600"> Team Member's First Name:</label>
            <input
              type="text"
              className="px-4 py-1 my-2 w-full border"
              value={memberData?.FirstName}
              onChange={(e) =>
                setMemberData({ ...memberData, FirstName: e.target.value })
              }
            />
            <label className="text-gray-600"> Team Member's Last Name:</label>
            <input
              type="text"
              className="px-4 py-1 my-2 w-full border"
              value={memberData?.LastName}
              onChange={(e) =>
                setMemberData({ ...memberData, LastName: e.target.value })
              }
            />
            <label className="text-gray-600"> Team Member's Email:</label>
            <input
              type="email"
              className="px-4 py-1 my-2 w-full border"
              value={memberData?.Email}
              onChange={(e) =>
                setMemberData({ ...memberData, Email: e.target.value })
              }
            />
            <label>Team Member's Role:</label>
            <div className="pb-4 mt-2 text-sm text-gray-600">
              <div className="flex items-start gap-3">
                <div>
                  <input
                    type="radio"
                    value="Admin"
                    name="role"
                    checked={memberData?.Role === "Admin"}
                    onChange={(e) =>
                      setMemberData({ ...memberData, Role: e.target.value })
                    }
                  />
                </div>
                <div className="">
                  Administrator: This role can perform most of the actions that
                  an owner can, but cannot remove an owner or delete the
                  organization.
                </div>
              </div>
              <div className="flex items-start gap-3">
                <div>
                  <input
                    type="radio"
                    value="Team"
                    name="role"
                    checked={memberData?.Role === "Team"}
                    onChange={(e) =>
                      setMemberData({ ...memberData, Role: e.target.value })
                    }
                  />
                </div>
                <div className="">
                  {" "}
                  Team: This role can be included in communications with the
                  organization, including chat and email, but does not have
                  access to the organization's dashboard or administrative
                  functions.
                </div>
              </div>
            </div>
            <label className="text-gray-600">
              {" "}
              Team Member's Title (optional):
            </label>
            <input
              type="text"
              className="px-4 py-1 my-2 w-full border"
              value={memberData?.Title}
              onChange={(e) =>
                setMemberData({ ...memberData, Title: e.target.value })
              }
            />
            <button
              className="px-4 py-2 bg-blue-500 rounded-sm my-3 w-full text-white"
              onClick={handleSubmit}
            >
              {" "}
              Submit{" "}
            </button>
          </Modal>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default TeamHome;
