import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getReqParamheader } from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { Breadcrumb } from "antd";
import { Helmet } from "react-helmet"; // Import Helmet for meta tags
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import BaseBackButton from "../../components/BaseBackButton";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../../components/PageSnipper";

function View() {
  const [articleId, setArticleId] = useState("");
  const [data, setData] = useState({});
  const [orgName, setOrgName] = useState("");
  const path = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const [description, setDescription] = useState("");
  let des = htmlToReactParser.parse(data?.draft?.Content);
  let currentOrgid = getItem("org_id");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let article = path.split("/")[2];
    setArticleId(article);
    let org = searchParams.get("org");
    setOrgName(org);
  }, []);

  useEffect(() => {
    if (articleId) {
      fetchArticledata(articleId);
    }
  }, [articleId]);

  const fetchArticledata = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          let dataa = res?.data?.data;
          let desp = dataa?.draft?.Content.replace(/<[^>]*>?/gm, "").substring(
            0,
            100
          );
          setDescription(desp);
          setLoading(false);
          setData(dataa);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(`${err?.response?.data?.massage} .....`);
        }, 1000);
      });
  };

  const handleOrg = () => {
    navigate(`/manage/${currentOrgid}`);
  };

  const navigateToArticleList = () => {
    navigate(`/manage/${currentOrgid}/article`);
  };

  const newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div
          className="w-[50%] px-8 py-4 mt-4 bg-white"
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            marginBottom: "3%",
          }}
        >
          {/* Add Helmet for Meta Tags */}
          <Helmet>
            <title>{data?.draft?.Title || "Article Title"}</title>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta
              property="og:title"
              content={data?.draft?.Title || "Article Title"}
            />
            <meta
              property="og:description"
              content={description || "Article description"}
            />
            <meta
              property="og:image"
              content={data?.draft?.Featured_Photo || "default-image-url"}
            />
            <meta property="og:url" content={`https://smorgborg.org${path}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Smorgborg" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:title"
              content={data?.draft?.Title || "Article Title"}
            />
            <meta
              property="twitter:description"
              content={description || "Article description"}
            />
            <meta
              property="twitter:image"
              content={data?.draft?.Featured_Photo || "default-image-url"}
            />
          </Helmet>

          <Breadcrumb
            items={[
              {
                title: (
                  <button onClick={navigateToArticleList}>Articles</button>
                ),
              },
              {
                title: data?.draft?.Title,
              },
            ]}
          />

          <div className="w-full mt-0 flex justify-center">
            {data?.draft?.Featured_Photo && (
              <img
                src={data?.draft?.Featured_Photo}
                className="max-h-[600px] max-w-[600px] px-4 py-4"
                alt="Article Featured"
              />
            )}
          </div>
          <p className="">{data?.draft?.Title} </p>
          <div className="flex justify-between items-start mt-2">
            <div className="text-gray-600 text-1xl">
              <p
                className="text-gray-600 cursor-pointer hover:text-blue-800"
                onClick={handleOrg}
              >
                {orgName}
              </p>
              {new Date(data?.publishedAt).toLocaleString(
                "en-US",
                newDateOptions
              )}
            </div>

            <div className="text-gray-600 text-1xl flex items-center gap-3">
              Share
              <FacebookShareButton url={`https://smorgborg.org${path}`}>
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <TwitterShareButton url={`https://smorgborg.org${path}`}>
                <TwitterIcon size={32} />
              </TwitterShareButton>
            </div>
          </div>
          <hr style={{ marginTop: "4px" }} />

          <div className="w-full mt-2 mb-4 text-left">{des}</div>
          <BaseBackButton
            type="button"
            text="Go Back to Article List"
            clickHandler={navigateToArticleList}
          />
        </div>
      )}
    </div>
  );
}

export default View;
