import React from "react";
import EmailTemplate from "../../components/EmailTemplate";

function CreateEmail() {
  return (
    <div>
      <EmailTemplate />
    </div>
  );
}

export default CreateEmail;
