import React from 'react'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import RootRouter from './RootRouter';
import MetaDes from './components/MetaDes'
import { useLocation } from 'react-router-dom';


function App() {
  const path=useLocation().pathname;
  const widgetpresent = path.includes("/widget/");

  return (
      <>
      <MetaDes title={path?.split('/')[3]  || 'Smorgborg | Helping Organization'} />
      { !widgetpresent ? 
        <Navbar />
        : ""
      }
      <RootRouter />
      </>
  );
}

export default App;
