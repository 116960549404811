import React, { useState } from "react";
import { Card, Input, Form } from "antd";
import isEmpty from "lodash/isEmpty";

const CHARCOAL = "#606060";

const HomeForm = () => {
  const [state, setState] = useState({
    email: "",
    emailError: undefined,
    name: "",
    nameError: undefined,
    phone: "",
    phoneError: undefined,
    referredBy: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const { email, phone, referredBy, name } = state;
    if (isEmpty(name)) {
      valid = false;
      setState((prevState) => ({
        ...prevState,
        nameError: "Name is required",
      }));
    }
    if (isEmpty(email) && isEmpty(phone)) {
      valid = false;
      setState((prevState) => ({
        ...prevState,
        emailError: "Email or phone is required",
        phoneError: "Phone or email is required",
      }));
    }
    if (valid) {
      console.log(state);
      setFormSubmitted(true);
    }
  };

  return (
    // <div className="h-full relative align-top">
    <div className="flex">
      {!formSubmitted && (
        <Card className="flex-1 bg-[#e6e6e6]">
          <Form className="text-left" layout="vertical">
            <Form.Item
              label="Your Name"
              validateStatus={!isEmpty(state.nameError) ? "error" : null}
              help={state.nameError}
            >
              <Input
                value={state.name}
                placeholder="John Doe"
                onChange={({ target: { value } }) =>
                  setState((prevState) => ({
                    ...prevState,
                    nameError: "",
                    name: value,
                  }))
                }
              />
            </Form.Item>
            <Form.Item label="Referred By">
              <Input
                value={state.referredBy}
                placeholder="XYZ"
                onChange={({ target: { value } }) =>
                  setState((prevState) => ({
                    ...prevState,
                    referredBy: value,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              validateStatus={!isEmpty(state.phoneError) ? "error" : null}
              help={state.phoneError}
            >
              <Input
                value={state.phone}
                placeholder="Enter your 10 digit mobile number"
                onChange={({ target: { value } }) =>
                  setState((prevState) => ({
                    ...prevState,
                    emailError: "",
                    phoneError: "",
                    phone: value,
                  }))
                }
              />
            </Form.Item>

            <Form.Item
              label="Email"
              validateStatus={!isEmpty(state.emailError) ? "error" : null}
              help={state.emailError}
            >
              <Input
                value={state.email}
                placeholder="xyz@gmail.com"
                onChange={({ target: { value } }) =>
                  setState((prevState) => ({
                    ...prevState,
                    emailError: "",
                    phoneError: "",
                    email: value,
                  }))
                }
              />
            </Form.Item>
            <button
              className="px-3 py-1 border rounded-md bg-blue-500 cursor-pointer hover:bg-blue-700
                          text-white float-right"
              onClick={handleSubmit}
            >
              Submit
            </button>

            <div className="clear-both" />
          </Form>
        </Card>
      )}
      {formSubmitted && (
        <div className="relative inline-block align-top w-full md:w-1/2">
          <div className="absolute top-1/2 transform -translate-y-1/2">
            <p className="text-base">
              Thanks, I will be in touch with you in just a day or two!
              <br />
            </p>
            <p className="text-base text-right">
              - John
              <br />
              Smorgborg, Founder
            </p>
          </div>
        </div>
      )}
      <div className="hidden md:block w-[30%] mx-10">
        <img
          alt="John"
          src="https://smorgborg.imgix.net/assets/john.png?fit=max&w=1200"
          loading="lazy"
        />
      </div>
    </div>
    // </div>
  );
};

export default HomeForm;
