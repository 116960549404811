import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DefaultLogo from "./email/DefaultLogo";
import CustomButton from "./CustomButton";
import { LogOut } from "lucide-react";

function LeftSidebar({ orgName, items, baseUrl }) {
  const [active, setActive] = useState("Dashboard");
  const path = useLocation().pathname;

  useEffect(() => {
    let str = path.split("/")[3];
    if (str && path.split("/")[1] !== "user") {
      str = str.charAt(0).toUpperCase() + str.slice(1);
      setActive(str);
    } else {
      if (path.split("/")[2] === "setting") {
        setActive("Setting");
      } else {
        setActive("Dashboard");
      }
    }
  }, [path, orgName]);

  return (
    <>
      <div className="h-full hidden md:block text-white bg-[#001529] px-4 py-2 min-w-44 ">
        <div className="py-2 border-b-2 border-gray-400">
          <DefaultLogo path={path} />
        </div>
        <div className="py-4 flex gap-2 flex-col h-[calc(100% - 4rem)] overflow-y-auto">
          {items?.map((list, i) => (
            <Link key={i} to={`${baseUrl}${list?.url}`}>
              <div
                className={` 
                  ${
                    active === list.name
                      ? "bg-blue-600 font-medium"
                      : "hover:bg-gray-700"
                  } flex gap-2 lg:gap-3 items-center rounded-md transition-all py-2.5 px-4 cursor-pointer text-white
                `}
                onClick={() => setActive(list?.name)}
              >
                {list?.icon && <div>{list?.icon}</div>}
                <p className="transition-all text-sm lg:text-base">
                  {" "}
                  {list?.name}{" "}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Mobile sidebar */}
      <div className="block md:hidden h-full text-white bg-[#001529] px-4 py-2 w-20">
        <div className="w-12 h-12 rounded-full border-2 border-gray-400 flex justify-center items-center py-2">
          <p className="text-2xl font-bold">{orgName?.charAt(0)}</p>
        </div>
        <div className="py-4 flex gap-2 flex-col">
          {items?.map((list, i) => (
            <div key={i} className="relative group">
              <Link to={`${baseUrl}${list?.url}`}>
                <div
                  className={` 
                ${
                  active === list.name
                    ? "bg-blue-600 font-medium"
                    : "hover:bg-gray-700"
                } flex gap-4 items-center rounded-md transition-all py-3 px-3 cursor-pointer text-white
              `}
                  onClick={() => setActive(list?.name)}
                >
                  {list?.icon && <div>{list?.icon}</div>}
                </div>

                {/* Tooltip */}
                <div className="absolute left-[3.5rem] top-0 text-left bg-[#001529] px-4 py-2 rounded-sm text-white opacity-0 hidden group-hover:block group-hover:opacity-100 transition-opacity duration-300 border-2 border-gray-500 z-50">
                  {list?.name}

                  <div className="absolute w-3 h-3 top-3.5 -left-1.5 rotate-45 bg-[#001529] border-l-2 border-b-2 border-gray-500 z-50"></div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LeftSidebar;
