import React from "react";

const HomeFeaturesList = () => {
  return (
    <div className="h-auto md:h-[480px] overflow-hidden flex flex-col md:flex-row text-xl md:text-2xl bg-orange-500 text-white">
      <div className="h-full w-full md:w-1/2 p-8 md:py-22 md:pl-24 md:pr-0 py-20">
        <h1 className="font-bold">All The Tools You Need In One Place</h1>
        <p className="mt-1 leading-10 text-balance text-[1.4rem]">
          We help you accomplish your organization's day-to-day goals, with
          tools that help you:
        </p>
        <ul className="mt-8 leading-10 list-disc text-[1.4rem]">
          <li>Manage Multiple Lists of Contacts</li>
          <li>Create Professional Looking Articles and Events</li>
          <li>Send Emails & Newsletters</li>
          <li>Connects to your Social Media</li>
          <p>
            <em>And Much More...</em>
          </p>
        </ul>
      </div>
      <div className="h-full w-1/2 px-16 pt-8 hidden md:block">
        <img
          className="w-full md:w-4/5 object-cover"
          // src="images/clipboard.png"
          src={process.env.PUBLIC_URL + "/images/clipboard.png"}
          alt="Task List"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HomeFeaturesList;
