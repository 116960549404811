import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { postReqParam, getReq } from '../services/apiCall'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setItem } from '../utils/storage';
import { API_URL_CONSTANT } from '../constants/apiConstant';
import { CheckMark } from '../assests/icons/email/BaseIcon';
import PageSnipper from '../components/PageSnipper';

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [id, setId] = useState('')
    const [loading, setLoading] = useState(false);
    const [vEmail, setVEmail] = useState({ msg: '', v: false })
    const [passwordType, setPasswordType] = useState("password");
    const navigate = useNavigate();
    const path = useLocation()

    useEffect(() => {
        let t = path?.search.split('=')[1]
        if (t) {
            setId(t)
        }
    }, [path])
    useEffect(() => {
        if (id) {
            verifyEmail(id)
        }
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault()
        //
        let data = {
            email,
            password
        }
        signin(data)
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const emailValidation = (val) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(val)) {
            setVEmail({ ...vEmail, v: true, msg: 'Please provide a valid user name' })
            return false;
        }
        else {
            setVEmail({ ...vEmail, v: false, msg: '' })
            return true
        }

    }
    const signin = (data) => {
        setLoading(true)
        let url = `${API_URL_CONSTANT.baseUrl}/login`
        postReqParam(url, data)
            .then((res) => {
                if (res?.status) {
                    console.log('response', res?.data)
                    setItem('token', res?.data?.data?.token)
                    setItem('user_id', res?.data?.data?.userId)
                    toast.success('LogIn Successful')
                    setLoading(false)
                    navigate("/");
                }
                else {
                    console.log('response err', res?.message)

                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                toast.error(err?.response?.data?.message)
                // toast.error('Something went wrong !')
            })

    }
    const signup = (data) => {
        setLoading(false)
        navigate("/signup");
    }
    const verifyEmail = (id) => {
        // verify/:userId",
        setLoading(true)
        let url = `${API_URL_CONSTANT.baseUrl}/verify/${id}`
        getReq(url)
            .then((res) => {
                if (res?.status) {
                    // console.log('verify email response', res?.data)
                    setLoading(false)
                    toast.success(' Verified Email... !')
                    setTimeout(() => {
                        navigate('/login')
                    }, 2000)
                }
                else {
                    console.log('response err', res?.message)
                    // toast.success(res?.message) 
                }
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
                toast.error('Something went wrong !')
            })
    }
    return (
        <>
            {
                loading ?
                    <PageSnipper loading={loading} />
                    :


                    <div className="w-full mt-5 flex justify-center items-center flex-col">
                        <form className="bg-white shadow-md rounded px-8 py-10 w-[80%] md:w-[30%]" onSubmit={handleSubmit}>
                            <div className='mb-4'>
                                <label className="block text-gray-700 text-sm  mb-2">
                                    Username
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700
                         leading-tight focus:shadow-outline"
                                    type="email" placeholder="username" required
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        emailValidation(e.target.value)
                                    }
                                    } />

                                <p className={vEmail?.v ? 'block text-red-700 font-light' : "hidden"}>

                                    {
                                        vEmail?.msg
                                    }

                                </p>
                            </div>
                            <div className="mb-2">
                                <label className="block text-gray-700 text-sm  mb-2" >
                                    Password
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3
                         text-gray-700 leading-tight focus:shadow-outline" type={passwordType}
                                    placeholder="*********" required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className='flex mb-3'>

                                <input type='checkbox' value='password' checked={passwordType !== "password"}
                                    onChange={(e) => togglePassword()}
                                />

                                <label className="block text-gray-700 text-sm ml-3" >
                                    {
                                        passwordType === "password" ?
                                            ' Show Password'
                                            :
                                            'Hide Password'
                                    }

                                </label>

                            </div>


                            <div className="block text-gray-700 text-sm  mb-2">
                                <p className='pb-3'>All checkmarks must turn green, password must have:</p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.length > 7} />
                                    <span> At least 8 characters </span>
                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password && password[0] === password[0]?.toUpperCase()} />
                                    <span> Fitst letter should be uppercase  </span>
                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.match(/[a-z]/)} />
                                    <span>
                                        At least 1 lowercase letter
                                    </span>

                                </p>
                                <p className='flex gap-3'>
                                    <CheckMark type={password?.match(/[0-9]/) && password?.match(/[!@#$%^&*]/)} />
                                    <span>
                                        At least 1 number and special character
                                    </span>
                                </p>
                            </div>
                            <div className="flex items-center justify-between">
                                <Link to='/reset_password'>
                                    <p className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
                                        Forgot Password?
                                    </p>
                                </Link>

                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
                    rounded focus:outline-none focus:shadow-outline" type="submit"
                                >
                                    Sign In
                                </button>
                            </div>
                            <div className="flex items-start justify-start text-sm">
                                If you are a new user, <span className="text-blue-800 cursor-pointer" onClick={signup}>&nbsp;<em>SIGN UP</em></span>
                            </div>
                            <ToastContainer />
                        </form>
                    </div>
            }
        </>
    )
}

export default Login
