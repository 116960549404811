export default function Modal({
  setOpenModal,
  title,
  children,
  type,
  item,
  remove,
  onClose,
}) {
  const handleClose = () => {
    setOpenModal(false);
    if (onClose) {
      console.log(true);
      onClose(); // Call onClose when modal is closed
    }
  };

  return (
    <>
      {type === "contact" ? (
        <>
          <div className="fixed inset-0 z-10 my-10 w-full">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={handleClose}
            ></div>
            <div className="flex items-center min-h-screen">
              <div className="relative w-[50%] min-w-[32rem] py-3 mx-auto bg-white rounded-md shadow-lg">
                <div className="flex justify-between border-b pb-3">
                  <div className="px-4 flex justify-between w-full items-center">
                    <div className="flex gap-2 items-center">
                      <h1 className="text-xl font-semibold pe-2">
                        Edit Profile
                      </h1>
                      {/* <p className="ps-4 text-lg text-gray-700 capitalize">
                        {item?.FirstName} {item?.LastName},
                        <span className="text-sm text-gray-600 lowercase">
                          {" "}
                          {item?.Email}
                        </span>
                      </p> */}
                    </div>
                  </div>
                  <div onClick={handleClose} className="cursor-pointer pr-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="px-6 py-2 relative">{children}</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-sm">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40 backdrop-blur-sm"
            onClick={handleClose}
          ></div>
          <div className="flex items-center min-h-screen">
            <div className="relative w-full max-w-[40rem] py-3 mx-auto bg-white rounded-md shadow-lg">
              <div className="flex justify-between border-b pb-3">
                <div className="text-bold pl-6">{title}</div>
                <div onClick={handleClose} className="cursor-pointer pr-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className="px-6 py-2">{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
