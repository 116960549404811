import React, { useState, useEffect, useRef } from "react";
import TextEditter from "../../components/TextEditter";
import {
  postReqParamheaderFile,
  getReqParamheader,
  postReqParamheader,
  putReqParamheaderFile,
} from "../../services/apiCall";
import { getItem } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import Modal from "../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import BaseTabs from "../../components/BaseTabs";
import ArticleEditor from "../../components/ArticleEditor";
import PageSnipper from "../../components/PageSnipper";
import BaseBackButton from "../../components/BaseBackButton";
import { Trash2, Save, ImageUp } from "lucide-react";
import CustomButton from "../../components/CustomButton";

function CreateArticle() {
  const [title, setTitle] = useState("");
  const [imgfile, uploadimg] = useState("");
  const [content, setContent] = useState("<p>dummy content</p>");
  const [org, setOrg] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublice] = useState(false);
  const [articleId, setArticleId] = useState("");
  const path = useLocation().pathname;
  const [img, setImg] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("Edit");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [albumList, setAlbumList] = useState([]);
  const [unsorted, setUnsorted] = useState([]);
  const [show, setShow] = useState(false);
  const [activeAlbum, setActiveAlbum] = useState({
    name: "unsorted",
    data: [],
  });
  const [msg, setMsg] = useState(
    "Your work is saved, but has not been published."
  );

  const count = useRef(0);

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    let article = path.split("/")[4];

    setOrg(orgid);
    setUser(userid);
    if (article !== "create") {
      setArticleId(article);
      setIsEdit(true);
    }
    if (article === "create") {
      // setArticleId(article);
      setIsEdit(false);
    }
  }, []);
  useEffect(() => {
    if (user && org) {
      fetchPhotos();
      albumDetails();
    }
    if (articleId && isEdit) {
      fetchArticledata(articleId);
    }
  }, [articleId, user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleAutoSave();
    }, 5 * 1000);

    return () => clearTimeout(timer);
  }, [title, content, imgfile]);

  const handleAutoSave = () => {
    if (title && content) {
      handleSave();
    }
  };

  const fetchArticledata = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log(
            "get article by id response",
            res?.data?.data?.draft?.Content
          );
          let data = res?.data?.data;
          setContent(data?.draft?.Content);
          setTitle(data?.draft?.Title);
          if (data?.draft?.Featured_Photo) {
            uploadimg(data?.draft?.Featured_Photo);
            setImg(true);
          }
          if (data?.publishedAt) {
            setIsPublice(true);
            setMsg("Your work is saved and published.");
          } else {
            setMsg("Your work is saved, but has not been published.");
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleContent = (txt) => {
    setContent(txt);
  };
  const handleTab = (txt) => {
    setActiveTab(txt);
  };

  const handlePublice = () => {
    if (window.confirm("Do you want to publish this article..!")) {
      handlePubliceArticle(articleId);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
  };
  const handlePubliceArticle = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/publish_Article/${id}/${user}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("publice article response", res?.data);
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSave = () => {
    setMsg("Your work is being saved .....");
    let data = {};
    data.Title = title;
    data.Content = content;
    data.Featured_Photo = imgfile;
    if (data && user && org) {
      if (isEdit) {
        updateArticle(data, user, org);
      } else {
        saveArticle(data, user, org);
      }
    }
  };

  const saveArticle = (data, userId, orgId) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Create_article/${orgId}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("save article response", res?.data);
          // toast.success(res?.data?.message)
          //handlePublice(res?.data?.data?._id)
          setArticleId(res?.data?.data?._id);
          setIsEdit(true);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const updateArticle = (data, userId, orgId) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Update_articles/${articleId}/${orgId}/${userId}`;
    putReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("update article response", res?.data);
          //toast.success(res?.data?.message)
          //handlePublice(articleId)
          if (articleId) {
            fetchArticledata(articleId);
          }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleUpload = () => {
    setShowModal(!showModal);
  };
  const handleUploadPhoto = () => {
    setShow(!show);
  };

  const albumDetails = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/albumdetails/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get all album response", res?.data);
          setAlbumList(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const fetchPhotos = () => {
    let url = `${API_URL_CONSTANT.baseUrl}/unsortedPhotos/${org}/${user}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get all photos response", res?.data);
          setUnsorted(res?.data?.data);
          let photosdata = res?.data?.data;
          let orglogopath = getItem("currentOrgLogo");
          let orglogo = {
            _id: "999",
            tags: [],
            Photo_Path: orglogopath,
            PhotoAlbumId: "",
            orgId: org,
            deleted: false,
            deletedAt: null,
            deletedById: null,
            lastAccessedAt: {
              userId: "",
              time: "",
            },
            modifiedById: "",
            modifiedAt: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
          };
          photosdata.push(orglogo);
          setActiveAlbum({
            ...activeAlbum,
            name: "unsorted",
            data: photosdata,
          });
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleselect = (val) => {
    if (val === "unsorted") {
      console.log(unsorted);
      setActiveAlbum({ ...activeAlbum, name: "unsorted", data: unsorted });
    } else {
      let data = albumList.filter((item) => item?.Album_Name === val);
      console.log(data);
      setActiveAlbum({
        ...activeAlbum,
        name: data[0]?.Album_Name,
        data: data[0]?.photoslist,
      });
    }
  };
  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];

      // Check if the file size is less than 2MB
      if (file.size > 2 * 1024 * 1024) {
        e.target.value = null;
        alert("File size should be less than 2MB.");
        return;
      }

      uploadimg({
        picturePreview: URL.createObjectURL(file),
        pictureAsFile: file,
      });
    }
  };
  const handleUploadSave = () => {
    setShow(false);
    if (user && org && imgfile) {
      let data = {};
      data.files = imgfile?.pictureAsFile;
      uploadPhoto(data);
    }
  };
  const uploadPhoto = (data) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/uploadPhotos/${org}/${user}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          console.log("upload photo response", res?.data);
          uploadimg({});
          fetchPhotos();
          setLoading(false);
          setTimeout(() => {
            toast.success(res?.data?.message);
          }, 1000);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  return (
    <div className="relative px-4 w-full flex justify-center items-center flex-col">
      <div className="absolute top-0 left-10">
        <BaseBackButton text="Article List" />
      </div>

      <p className="text-2xl text-center">
        {!title ? "Untitled Article" : title}{" "}
      </p>

      <div className="w-[50%] mt-4">
        <BaseTabs
          active={activeTab}
          handleTab={handleTab}
          tabList={["Edit", "Publish Settings"]}
        />
      </div>
      {activeTab === "Edit" ? (
        <div className="flex justify-center items-center flex-col my-3">
          <div className="w-full">
            <label> Article Title :</label>
            <input
              type="text"
              value={title}
              // onBlur={handleAutoSave}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Enter title of your article"
              className="w-full px-4 py-2 my-3"
            />
          </div>
          <div className="mt-2 w-full">
            <label> Feature Photo :</label>
            <div className="border rounded-sm bg-white py-4 my-3">
              {!img ? (
                <div>
                  <div className="flex justify-center ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-100 h-40"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>
                  </div>
                  <p
                    className="text-small text-blue-600 cursor-pointer text-center"
                    onClick={handleUpload}
                  >
                    {" "}
                    Choose a feature Photo
                  </p>
                </div>
              ) : (
                <div className="flex justify-center flex-col items-center">
                  <img
                    src={imgfile}
                    className="max-h-[400px] max-w-[400px]"
                    alt="med1"
                  />
                  <p
                    className="text-small text-blue-600 cursor-pointer text-center mt-3"
                    onClick={() => {
                      setImg(!img);
                      uploadimg("");
                    }}
                  >
                    {" "}
                    Remove and Choose New
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2">
            <label> Content :</label>
            <div className="my-3 w-[600px]">
              {/* <TextEditter txt={content} handleTxt={handleContent}  /> */}
              <ArticleEditor txt={content} handleTxt={handleContent} />
            </div>
          </div>

          <div className="flex justify-end  w-full  my-2">
            <button
              className="border rounded-md bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
              onClick={() => setActiveTab("Publish Settings")}
            >
              Next
            </button>
          </div>
          <p className="flex justify-end  w-full text-gray-500 text-sm">
            {" "}
            {msg}
          </p>
        </div>
      ) : (
        <div className="w-[50%] text-start">
          <p className="text-sm text-gray-500">
            This article is currently
            <span className="text-sm text-blue-500 pl-2">
              {isPublished ? "published" : "not published"}{" "}
            </span>
          </p>
          <div className="flex justify-between pt-6">
            <button
              className="border rounded-md  bg-white text-gray-500 px-4 py-2 hover:border-blue-400 hover:text-blue-400"
              onClick={() => setActiveTab("Edit")}
            >
              {" "}
              Back
            </button>
            <button
              className="border rounded-md  bg-blue-600 text-white px-4 py-2 hover:bg-blue-400"
              onClick={() => handlePublice()}
            >
              {" "}
              Publish and Exit
            </button>
          </div>
          <p className="text-end mt-3 text-gray-500 text-sm"> {msg}</p>
        </div>
      )}

      {showModal && (
        <Modal setOpenModal={setShowModal} title="Select Photo">
          <div className="my-2 flex gap-4 items-center justify-between border-b-2 border-gray-300 pb-4 divide-x-2 divide-gray-600">
            <div className="flex gap-2 flex-1 items-center w-full">
              <p className="text-gray-700 font-medium">Choose from album </p>
              <select
                className=" border border-gray-300 py-1 px-2
                 text-gray-700 text-sm rounded-sm bg-white"
                onChange={(e) => handleselect(e.target.value)}
              >
                <option value="unsorted"> Unsorted</option>
                {albumList?.length > 0 &&
                  albumList.map((item, i) => (
                    <option value={item?.Album_Name} key={i}>
                      {item?.Album_Name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="ps-8">
              <CustomButton
                icon={<ImageUp size={20} />}
                bgColor={"bg-blue-600"}
                content={"Upload Photo"}
                clickHandler={handleUploadPhoto}
                additionalStyles={"text-white hover:bg-blue-700"}
              />
            </div>
          </div>
          {loading ? (
            <PageSnipper loading={loading} />
          ) : (
            <div className="grid grid-cols-4 gap-4 mt-4 h-[60vh] overflow-y-auto">
              {activeAlbum &&
                activeAlbum?.data?.map((item, i) => (
                  <div key={i} className="rounded-sm">
                    {activeAlbum?.name === "unsorted" ? (
                      <div
                        onClick={() => {
                          uploadimg(item?.Photo_Path);
                          setImg(!img);
                          setShowModal(false);
                        }}
                        className="cursor-pointer h-[130px] rounded-sm  object-contain"
                      >
                        <img
                          src={item?.Photo_Path}
                          style={{ height: "inherit" }}
                          alt="photo path missing"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          uploadimg(item?.Photo_Path);
                          setImg(!img);
                          setShowModal(false);
                        }}
                        className="cursor-pointer h-[130px] rounded-sm  object-contain"
                      >
                        <img
                          src={item?.Photo_Path}
                          style={{ height: "inherit" }}
                          alt="photo path missing"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </Modal>
      )}
      {show && (
        <Modal setOpenModal={setShow} title="Upload Photo">
          <label className="text-gray-600"> Select Photo:</label>
          {imgfile?.picturePreview ? (
            <div className="w-full flex justify-center flex-col">
              <img
                src={imgfile?.picturePreview}
                alt="photo"
                className="max-h-[400px] max-w-[400px]"
              />
            </div>
          ) : (
            <>
              <input
                type="file"
                className="px-4 py-1 my-2 w-full border"
                accept=".jpg,.jpeg,.png"
                onChange={imgFilehandler}
              />
              <p className="text-[0.8rem] text-gray-600 font-semibold ps-4">
                Photo size should be less than{" "}
                <span className="font-bold">2MB</span>
              </p>
            </>
          )}

          <div className="flex mt-4 justify-between items-center">
            {imgfile?.picturePreview && (
              <CustomButton
                icon={<Trash2 size={20} />}
                bgColor={"bg-red-600"}
                content={"Remove"}
                clickHandler={() => uploadimg({})}
                additionalStyles={"text-white hover:bg-red-700"}
              />
            )}

            <CustomButton
              icon={<Save size={20} />}
              bgColor={"bg-blue-600"}
              content={"Save Photo"}
              clickHandler={
                activeAlbum?.name === "unsorted" && handleUploadSave
              }
              additionalStyles={"text-white hover:bg-blue-700 self-end"}
              disabled={!imgfile?.picturePreview}
            />
          </div>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
}

export default CreateArticle;
