// import React from "react";

// const Hero = () => {
//   return (
//     <div className="bg-orange-500 py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
//         <h1 className="text-xl leading-8 text-white mb-4">
//           {
//             '"This Platform has made my life so much easier in creating events, sending emails, press releases and social media. We now have our contacts divided into separate mailing lists and we can track all our statistics for grants. Plus it offers lots of other features to keep me up to date."'
//           }
//         </h1>
//         <p className="text-white">
//           {"-- Patricia Frischer"}
//           <br />
//           {"Founder, San Diego Visual Arts Network"}
//           <br />
//           {"Co-Founder, North County Arts Network"}
//         </p>
//         <img
//           className="h-40 w-40 rounded-full mt-8 mx-auto"
//           src="https://smorgborg.imgix.net/assets/patricia.png?fit=max&w=1200"
//           alt="profile"
//         />
//       </div>
//     </div>
//   );
// };

// export default Hero;

import React from "react";

const Hero = () => {
  return (
    <div className="bg-orange-500 py-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-xl lg:text-3xl leading-8 text-white mb-4">
          {
            '"This Platform has made my life so much easier in creating events, sending emails, press releases and social media. We now have our contacts divided into separate mailing lists and we can track all our statistics for grants. Plus it offers lots of other features to keep me up to date."'
          }
        </h1>
        <p className="text-white text-sm lg:text-base">
          {"-- Patricia Frischer"}
          <br />
          {"Founder, San Diego Visual Arts Network"}
          <br />
          {"Co-Founder, North County Arts Network"}
        </p>
        <img
          className="h-24 sm:h-32 lg:h-40 w-24 sm:w-32 lg:w-40 rounded-full mt-8 mx-auto"
          src="https://smorgborg.imgix.net/assets/patricia.png?fit=max&w=1200"
          alt="profile"
        />
      </div>
    </div>
  );
};

export default Hero;
